import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import MobileMenu from './MobileMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faBars } from '@fortawesome/free-solid-svg-icons';

class NavBar extends Component{

    constructor(props){
        super(props);
        this.state = {};
        this.handleScroll = this.handleScroll.bind(this);

        this.mobileMenuElement = React.createRef();
    }

    activeMobileMenu = () => {
        this.mobileMenuElement.current.toggleMobileMenu();
    }

    handleScroll() {
        if(this.mount){
            this.setState({scroll: window.scrollY});
        }
    }
  
    componentDidMount() {
        this.mount = true;
        const el = document.querySelector('nav');
        this.setState({top: el.offsetTop, height: el.offsetHeight});
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentDidUpdate() {
        this.state.scroll > this.state.top ? 
        document.body.style.paddingTop = `${this.state.height}px` :
        document.body.style.paddingTop = 0;
    }

    componentWillUnmount(){
        this.mount = false;
    }

    render(){

        const registerButtonStyle = {
            // backgroundColor: '#007bff', // Example blue color
            backgroundColor: '#fd7e14',
            color: 'white',
            padding: '10px 20px',
            textAlign: 'center',
            display: 'block',
            margin: '10px auto',
            borderRadius: '5px',
            textDecoration: 'none'
        };

        return(
            <div>
                {/*====================  header area ====================*/}
                <div className={`header-area header-sticky header-sticky--default ${this.state.scroll > this.state.top ? "is-sticky" : ""}`}>
                    <div className="header-area__desktop header-area__desktop--default">
                    {/*=======  header top bar  =======*/}
                    <div className="header-top-bar">
                        <div className="container">
                        {/* <div className="row align-items-center">
                            <div className="col-lg-4">
                            <div className="top-bar-left-wrapper">
                                <div className="social-links social-links--white-topbar d-inline-block">
                                <ul>
                                    <li><a href="//facebook.com"><i className="zmdi zmdi-facebook" /></a></li>
                                    <li><a href="//twitter.com"><i className="zmdi zmdi-twitter" /></a></li>
                                    <li><a href="//vimeo.com"><i className="zmdi zmdi-vimeo" /></a></li>
                                    <li><a href="//linkedin.com"><i className="zmdi zmdi-linkedin-box" /></a></li>
                                    <li><a href="//skype.com"><i className="zmdi zmdi-skype" /></a></li>
                                </ul>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-8">
                            <div className="top-bar-right-wrapper">
                                <a href={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--default d-inline-block no-style-link">GET A QUOTE</a>
                            </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                    {/*=======  End of header top bar  =======*/}
                    {/*=======  header info area  =======*/}
                    <div className="header-info-area">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                <div className="header-info-wrapper align-items-center">
                                    <div className="logo">
                                        <Link to = {`${process.env.PUBLIC_URL}/`}>
                                            <img src="/assets/img/logo/DoI-LogoWhite.png" className="img-fluid" alt="Logo" />
                                        </Link>
                                    </div>
                                    <div className="header-contact-info">
                                    <div className="header-info-single-item">
                                        <div className="header-info-single-item__icon">
                                        <i className="zmdi zmdi-smartphone-android" />
                                        </div>
                                        <div className="header-info-single-item__content">
                                        <h6 className="header-info-single-item__title">Phone</h6>
                                        <p className="header-info-single-item__subtitle">080-26766583</p>
                                        </div>
                                    </div>
                                    <div className="header-info-single-item">
                                        <div className="header-info-single-item__icon">
                                        <i className="zmdi zmdi-home" />
                                        </div>
                                        <div className="header-info-single-item__content">
                                        <h6 className="header-info-single-item__title">Address</h6>
                                        <p className="header-info-single-item__subtitle">No 55, 2nd Floor , 1st Main Road ,Thyagarajanagar <br /> Bangalore- 560028</p>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="mobile-navigation-icon" id="mobile-menu-trigger"  onClick={this.activeMobileMenu}>
                                        {/* <i /> */}
                                        <FontAwesomeIcon icon={faBars} className='menu-icon' />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    {/*=======  End of header info area =======*/}
                    {/*=======  header navigation area  =======*/}
                    <div className="header-navigation-area default-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* header navigation */}
                                    <div className="header-navigation header-navigation--header-default position-relative">
                                    <div className="logo">
                                        <Link to = {`${process.env.PUBLIC_URL}/`}>
                                            <img src="/assets/img/logo/DoI-LogoWhite.png" className="img-fluid" alt="Logo" />
                                        </Link>
                                    </div>
                                        <div className="header-navigation__nav position-static">
                                        <nav>
                                            <ul>

                                            <li><Link to={`${process.env.PUBLIC_URL}/`} className="no-style-link">HOME</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/about-us`} className="no-style-link">ABOUT US</Link></li>

                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/our-services`} className="no-style-link">OUR SERVICES</Link></li> */}

                                            <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/our-services`} className="no-style-link">OUR SERVICES</Link>
                                                <ul className="submenu" style={{backgroundColor:'#3c5f8d'}}>
                                                {/* <li><Link to={`${process.env.PUBLIC_URL}/services`} className="no-style-link">Service Page</Link></li> */}
                                                <li><Link to={`${process.env.PUBLIC_URL}/pre-conference`} className="no-style-link link-style">Pre Conference</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/onsite-conference`} className="no-style-link link-style">Onsite Conference</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/post-conference`} className="no-style-link link-style">Post Conference</Link></li>
                                                </ul>
                                            </li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/our-team`} className="no-style-link">OUR TEAM</Link></li>

                                            <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/what-we-do`} className="no-style-link">INSTITUTIONAL EVENTS</Link>
                                                <ul className="submenu" style={{backgroundColor:'#3c5f8d'}}>
                                                {/* <li><Link to={`${process.env.PUBLIC_URL}/institutional-events`} className="no-style-link link-style" style={{color:'white'}}>Conferences</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/institutional-meetings`} className="no-style-link link-style">Meetings</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/symposium`} className="no-style-link link-style">Symposium</Link></li> */}
                                                <li><Link to={`${process.env.PUBLIC_URL}/what-we-do`} className="no-style-link link-style">What We Do</Link></li>
                                                </ul>
                                            </li>

                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/core-services`} className="no-style-link">CORE SERVICES</Link></li> */}

                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/corporate-events`} className="no-style-link">CORPORATE EVENTS</Link></li> */}

                                            <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/corporate-events-what-we-do`} className="no-style-link">CORPORATE EVENTS</Link>
                                                <ul className="submenu" style={{backgroundColor:'#3c5f8d'}}>
                                                <li><Link to={`${process.env.PUBLIC_URL}/corporate-events-what-we-do`} className="no-style-link link-style" style={{color:'white'}}>What We Do</Link></li>
                                                </ul>
                                            </li>

                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/DOI-incentives-holidays`} className="no-style-link">DOI INCENTIVES HOLIDAYS</Link></li> */}

                                            <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/DOI-incentives-holidays-what-we-do`} className="no-style-link">INCENTIVES HOLIDAYS</Link>
                                                <ul className="submenu" style={{backgroundColor:'#3c5f8d'}}>
                                                <li><Link to={`${process.env.PUBLIC_URL}/DOI-incentives-holidays-what-we-do`} className="no-style-link link-style" style={{color:'white'}}>What We Do</Link></li>
                                                </ul>
                                            </li>

                                            {/* <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/services`} className="no-style-link">MICE SERVICE</Link>
                                                <ul className="submenu">
                                                <li><Link to={`${process.env.PUBLIC_URL}/services`} className="no-style-link">Service Page</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/service-details-left-sidebar`} className="no-style-link">Service Details Left Sidebar</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/service-details-right-sidebar`} className="no-style-link">Service Details Right Sidebar</Link></li>
                                                </ul>
                                            </li> */}

                                            <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/know-india`} className="no-style-link">KNOW INDIA</Link>
                                                <ul className="submenu" style={{backgroundColor:'#3c5f8d'}}>
                                                <li><Link to={`${process.env.PUBLIC_URL}/know-india`} className="no-style-link link-style" style={{color:'white'}}>KNOW INDIA</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/know-karnataka`} className="no-style-link link-style" style={{color:'white'}}>KNOW KARNATAKA</Link></li>
                                                </ul>
                                            </li>

                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/incredible-india`} className="no-style-link">INCREDIBLE INDIA</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/know-india`} className="no-style-link">KNOW INDIA</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/know-karnataka`} className="no-style-link">KNOW KARNATAKA</Link></li> */}

                                            <li><Link to={`${process.env.PUBLIC_URL}/corporate-gifts`} className="no-style-link">CORPORATE GIFTS</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/our-works`} className="no-style-link">OUR WORKS</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/stage-layouts`} className="no-style-link">STAGE LAYOUTS</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/partner-network`} className="no-style-link">PARTNER NETWORK</Link></li>

                                            <li><Link to={`${process.env.PUBLIC_URL}/news`} className="no-style-link">NEWS</Link></li>

                                            {/* <li className="has-children has-children--multilevel-submenu">
                                                <Link to={`${process.env.PUBLIC_URL}/pre-conference`} className="no-style-link">CONFERENCES SERVICES</Link>
                                                <ul className="submenu" style={{backgroundColor:'#3c5f8d'}}>
                                                <li><Link to={`${process.env.PUBLIC_URL}/pre-conference`} className="no-style-link link-style">Pre Conference</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/onsite-conference`} className="no-style-link link-style">Onsite Conference</Link></li>
                                                <li><Link to={`${process.env.PUBLIC_URL}/post-conference`} className="no-style-link link-style">Post Conference</Link></li>
                                                </ul>
                                            </li> */}

                                            {/* <li><Link to={`${process.env.PUBLIC_URL}/contact-us`} className="no-style-link">CONTACT</Link> </li> */}
                                            </ul>
                                        </nav>

                                        <div className="logo right-logo">
                                            <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                                                <FontAwesomeIcon icon={faPhone} />
                                            </Link>
                                        </div>
                                        
                                        </div>

                                        {/* <div>
                                            <Link to={`${process.env.PUBLIC_URL}/contact-us`} style={registerButtonStyle}>
                                                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                    Contact <FontAwesomeIcon icon={faPhone} style={{ marginLeft: '10px' }} />
                                                </span>
                                            </Link>
                                        </div> */}

                                    </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    {/*=======  End of header navigation area =======*/}
                    </div>
                </div>
                {/*====================  End of header area  ====================*/}
                
                {/* Mobile Menu */}
                <MobileMenu ref={this.mobileMenuElement} />

            </div>
        )
    }
}


export default NavBar;