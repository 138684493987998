import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

class MobileMenu extends Component{

    state = {
        active : false
    }

    toggleMobileMenu = () => {
        this.setState({
            active: !this.state.active
        })
    }

    componentDidMount(){
        var offCanvasNav = document.getElementById('offcanvas-navigation');
        var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

        for (let i = 0; i<offCanvasNavSubMenu.length; i++){
           offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
        }

        var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
        var numMenuExpand = menuExpand.length;

        function sideMenuExpand() {

            if(this.parentElement.classList.contains('active') === true) {
                this.parentElement.classList.remove('active');
            } else {
                for (let i = 0; i < numMenuExpand; i++) {
                    menuExpand[i].parentElement.classList.remove('active');
                }
                this.parentElement.classList.add('active');
            }
        }

        for (let i = 0; i < numMenuExpand; i++) {
            menuExpand[i].addEventListener("click", sideMenuExpand);
        }
    }


    render(){

        const registerButtonStyle = {
            // backgroundColor: '#007bff', // Example blue color
            backgroundColor: '#fd7e14',
            color: 'white',
            padding: '10px 20px',
            textAlign: 'center',
            display: 'block',
            margin: '10px auto',
            borderRadius: '5px',
            textDecoration: 'none'
        };

        return(
            <div>
                {/*=======  offcanvas mobile menu  =======*/}
                <div className= {`offcanvas-mobile-menu ${this.state.active ? 'active': ''} `} id="mobile-menu-overlay">
                    <button className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={this.toggleMobileMenu}>
                        <i className="ion-android-close" />
                    </button>
                    <div className="offcanvas-wrapper">
                        <div className="offcanvas-inner-content">
                            {/* <div className="offcanvas-mobile-search-area">
                                <form action="#">
                                <input type="search" placeholder="Search ..." />
                                <button type="submit"><i className="fa fa-search" /></button>
                                </form>
                            </div> */}
                            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                                <ul>

                                    <li><Link to={`${process.env.PUBLIC_URL}/`} className="no-style-link" onClick={this.toggleMobileMenu}>HOME</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/about-us`} className="no-style-link">ABOUT US</Link></li>

                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/our-services`} className="no-style-link">OUR SERVICES</Link></li> */}

                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/our-services`} className="no-style-link">OUR SERVICES</Link>
                                        <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/pre-conference`} className="no-style-link">Pre Conference</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/onsite-conference`} className="no-style-link">Onsite Conference</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/post-conference`} className="no-style-link">Post Conference</Link></li>
                                        </ul>
                                    </li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/our-team`} className="no-style-link">OUR TEAM</Link></li>

                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/what-we-do`} className="no-style-link">INSTITUTIONAL EVENTS</Link>
                                        <ul className="sub-menu">
                                        {/* <li><Link to={`${process.env.PUBLIC_URL}/institutional-events`} className="no-style-link">Conferences</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/institutional-meetings`} className="no-style-link">Meetings</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/symposium`} className="no-style-link">Symposium</Link></li> */}
                                        <li><Link to={`${process.env.PUBLIC_URL}/what-we-do`} className="no-style-link">What We Do</Link></li>
                                        </ul>
                                    </li>

                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/core-services`} className="no-style-link">CORE SERVICES</Link></li> */}

                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/corporate-events`} className="no-style-link">CORPORATE EVENTS</Link></li> */}

                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/corporate-events-what-we-do`} className="no-style-link">CORPORATE EVENTS</Link>
                                        <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/corporate-events-what-we-do`} className="no-style-link">What We Do</Link></li>
                                        </ul>
                                    </li>

                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/DOI-incentives-holidays`} className="no-style-link">DOI INCENTIVES HOLIDAYS</Link></li> */}

                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/DOI-incentives-holidays-what-we-do`} className="no-style-link">INCENTIVES HOLIDAYS</Link>
                                        <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/DOI-incentives-holidays-what-we-do`} className="no-style-link">What We Do</Link></li>
                                        </ul>
                                    </li>

                                    {/* <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/services`} className="no-style-link">MICE SERVICE</Link>
                                        <ul className="sub-menu">
                                            <li><Link to={`${process.env.PUBLIC_URL}/services`} className="no-style-link">Service Page</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/service-details-left-sidebar`} className="no-style-link">Service Details Left Sidebar</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/service-details-right-sidebar`} className="no-style-link">Service Details Right Sidebar</Link></li>
                                        </ul>
                                    </li> */}

                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/know-india`} className="no-style-link">KNOW INDIA</Link>
                                        <ul className="sub-menu">
                                            <li><Link to={`${process.env.PUBLIC_URL}/know-india`} className="no-style-link">KNOW INDIA</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/know-karnataka`} className="no-style-link">KNOW KARNATAKA</Link></li>
                                        </ul>
                                    </li>

                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/incredible-india`} className="no-style-link">INCREDIBLE INDIA</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/know-india`} className="no-style-link">KNOW INDIA</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/know-karnataka`} className="no-style-link">KNOW KARNATAKA</Link></li> */}

                                    <li><Link to={`${process.env.PUBLIC_URL}/corporate-gifts`} className="no-style-link">CORPORATE GIFTS</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/our-works`} className="no-style-link">OUR WORKS</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/stage-layouts`} className="no-style-link">STAGE LAYOUTS</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/partner-network`} className="no-style-link">PARTNER NETWORK</Link></li>

                                    <li><Link to={`${process.env.PUBLIC_URL}/news`} className="no-style-link">NEWS</Link></li>

                                    {/* <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/pre-conference`} className="no-style-link">CONFERENCES SERVICES</Link>
                                        <ul className="sub-menu">
                                        <li><Link to={`${process.env.PUBLIC_URL}/pre-conference`} className="no-style-link">Pre Conference</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/onsite-conference`} className="no-style-link">Onsite Conference</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/post-conference`} className="no-style-link">Post Conference</Link></li>
                                        </ul>
                                    </li> */}
                                    
                                    {/* <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/projects`} >PROJECT</Link>
                                        <ul className="sub-menu">
                                            <li><Link to={`${process.env.PUBLIC_URL}/projects`} >Project Page</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/project-details`}>Project Details</Link></li>
                                        </ul>
                                    </li>
                                    <li className="menu-item-has-children">
                                        <Link to={`${process.env.PUBLIC_URL}/blog-left-sidebar`}>BLOG</Link>
                                        <ul className="sub-menu">
                                            <li><Link to={`${process.env.PUBLIC_URL}/blog-left-sidebar`}>Blog Left Sidebar</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/blog-right-sidebar`}>Blog Right Sidebar</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`}>Blog Details Left Sidebar</Link></li>
                                            <li><Link to={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`}>Blog Details Right Sidebar</Link></li>
                                        </ul>
                                    </li> */}
                                    {/* <li><Link to={`${process.env.PUBLIC_URL}/contact-us`} className="no-style-link">CONTACT</Link> </li> */}

                                    <li style={{ marginTop: '20px' }}>
                                        <Link to={`${process.env.PUBLIC_URL}/contact-us`} style={registerButtonStyle}>
                                            CONTACT US &nbsp; &nbsp;<FontAwesomeIcon icon={faPhone} />
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            {/* <div className="offcanvas-widget-area">
                                <div className="off-canvas-contact-widget">
                                <div className="header-contact-info">
                                    <ul className="header-contact-info__list">
                                    <li><i className="ion-android-phone-portrait" /> <a href="tel://12452456012" className="no-style-link">(1245) 2456 012 </a></li>
                                    <li><i className="ion-android-mail" /> <a href="mailto:info@yourdomain.com" className="no-style-link">info@yourdomain.com</a></li>
                                    </ul>
                                </div>
                                </div> */}
                                {/*Off Canvas Widget Social Start*/}
                                {/* <div className="off-canvas-widget-social">
                                <a href="#/" title="Facebook"><i className="fa fa-facebook" /></a>
                                <a href="#/" title="Twitter"><i className="fa fa-twitter" /></a>
                                <a href="#/" title="LinkedIn"><i className="fa fa-linkedin" /></a>
                                <a href="#/" title="Youtube"><i className="fa fa-youtube-play" /></a>
                                <a href="#/" title="Vimeo"><i className="fa fa-vimeo-square" /></a>
                                </div> */}
                                {/*Off Canvas Widget Social End*/}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {/*=======  End of offcanvas mobile menu  =======*/}

            </div>
        )
    }
}

export default MobileMenu;